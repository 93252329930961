<template>
    <section class="mantenimiento-planes px-3">
        <titulo-divisor titulo="Planes de mantenimiento">
            <div class="row">
                <div class="col auto my-auto">
                    <search v-model="search" :data-to-search="plansList" placeholder="Buscar" @filtered="handleFiltered" />
                </div>
                <div class="icon-option my-auto">
                    <el-tooltip content="Añadir plan" effect="light" visible-arrow>
                        <i class="icon-plus-circle f-30"
                            :class="permitAction('almacen.mantenimiento.crear') ? 'cr-pointer' : 'cr-not-allowed'" 
                            @click="functionPermitAction('almacen.mantenimiento.crear', addPlan)"
                        />
                    </el-tooltip>
                </div>
            </div>
        </titulo-divisor>
        <div class="row listado-planes">
            <div v-for="mantenimiento in plansList" :key="mantenimiento.id" class="col-lg-5 col-xl-4 mb-3" @click="goToPlan(mantenimiento.id)">
                <card-maintenance
                :name="mantenimiento.nombre"
                :description="mantenimiento.descripcion"
                :cant="mantenimiento.numero_actividades"    
                @edit="openEditMaintenance({e: $event, maintenance: mantenimiento})"
                @deleteItem="openDeleteMaintenance(mantenimiento)"
                />
            </div>
        </div>
        <default-image 
        v-if="plansList.length === 0"
        image="/img/default/no-maintenance.svg"
        text="No se han creado planes de mantenimiento"
        />
        <!-- partials  -->
        <modal-eliminar ref="openModalDeleteMaintenance" title="Eliminar plan de mantenimiento" mensaje="¿Está seguro que quiere eliminar plan de mantenimiento?" @delete="handleDelete" />
        <modal-add-plan ref="openModalAddPlan" @create="handleCreate" @update="handleUpdate"/>
    </section>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import cardMaintenance from '../components/cardMaintenance.vue'
import modalAddPlan from './partials/modalAddPlan.vue'
export default {
    components:{
        cardMaintenance,
        modalAddPlan,
    },
    data(){
        return{
            search: '',
            planToDelete: {},
            plansList: []
        }
    },
    computed: {
        ...mapGetters({
			user: 'auth/user',
            mantenimientos: 'almacen/mantenimiento/mantenimientos'
        })
    },
    methods:{
        ...mapActions({
            obtenerPlanesMantenimiento: 'almacen/mantenimiento/Action_get_plan_mantenimientos',
            crearPlanesMantenimiento: 'almacen/mantenimiento/Action_create_mantenimientos',
            eliminarPlanesMantenimiento: 'almacen/mantenimiento/Action_delete_mantenimientos',
            editarPlanesMantenimiento: 'almacen/mantenimiento/Action_edit_mantenimientos',
        }),

        addPlan(){
            this.$refs.openModalAddPlan.toggle()
        },
        openEditMaintenance({e, maintenance}){
            this.$refs.openModalAddPlan.edit(maintenance)
        },
        openDeleteMaintenance(maintenance){
            this.planToDelete = maintenance
            this.$refs.openModalDeleteMaintenance.toggle()
        },
        goToPlan(id){
            this.$router.push({ name: 'almacen.mantenimiento.planes.ver' , params:{ id_material: this.$route.params.id, id } })
        },
        handleFiltered(filteredData){
            this.plansList = filteredData.length ? filteredData : this.mantenimientos
        },
        async handleCreate(maintenance){    
            await this.crearPlanesMantenimiento({
                id_material: this.$route.params.id,
                payload: maintenance
            })
            this.plansList = this.mantenimientos
        },
        async handleUpdate(maintenance){
            await this.editarPlanesMantenimiento({
                id_material: this.$route.params.id,
                id_mantenimiento: maintenance.id,
                payload: maintenance
            })
            this.plansList = this.mantenimientos
        },
        async handleDelete(){
            await this.eliminarPlanesMantenimiento({
                id_material: this.$route.params.id,
                id_mantenimiento: this.planToDelete.id,
            })
            this.plansList = this.mantenimientos
        }
    },
    async mounted(){
        await this.obtenerPlanesMantenimiento({id_material: this.$route.params.id})
        this.plansList = this.mantenimientos
    }
}
</script>

<style lang="scss" scoped>
.mantenimiento-planes{
    .default-image{
        padding-top: 110px;
    }
}
</style>