    <template>
        <modal ref="modalAddPlan" :titulo="`${maintenance.id != '' ? 'Editar' : 'Crear'} plan de mantenimiento` " adicional="Guardar" @adicional="add">
            <ValidationObserver ref="validator">
                <div class="row m-3 f-15 justify-content-center">
                    <div class="col-10 mb-3">
                        <required text="Campos obligatorios"/>
                    </div>
                    <div class="col-10 mb-3">
                        <p class="input-label-top f-12">Nombre plan <required/></p> 
                        <ValidationProvider name="nombre" rules="required|max:255" v-slot="{ errors }">
                            <el-input v-model="maintenance.nombre" type="text" show-word-limit maxlength="255" class="padding-input w-100" size="small" />
                            <VeeError :text="errors[0]"/>
                        </ValidationProvider>
                    </div>
                    <div class="col-10">
                        <p class="input-label-top f-12">
                            Descripción
                        </p>
                        <ValidationProvider name="descripción" rules="max:700" v-slot="{ errors }">
                            <el-input v-model="maintenance.descripcion" type="textarea" show-word-limit maxlength="700" class="padding-input w-100" size="small" :autosize="{ minRows: 6, maxRows: 10 }" />
                            <VeeError :text="errors[0]"/>
                        </ValidationProvider>
                    </div>
                </div>
            </ValidationObserver>
        </modal>
    </template>

    <script>
    import { MaintenanceFactory } from '~/domain/entities/almacen/mantenimiento/factories/MaintenanceFactory'
    const maintenance = MaintenanceFactory.instantiateEmpty()
    export default {
        data(){
            return {
                maintenance: {...maintenance}
            }
        },
        methods: {
            toggle(){
                this.$refs.modalAddPlan.toggle()
            },
            edit(maintenance){
                this.maintenance = {...maintenance}
                this.toggle()
            },
            async add(){
                const valid = await this.$refs.validator.validate();
                if(!valid) return;

                this.maintenance.id != ''
                    ? this.$emit('update', this.maintenance)
                    : this.$emit('create', this.maintenance)

                this.reset()
                this.toggle()
            },
            reset(){
                this.maintenance = {...maintenance}
                this.$refs.validator.reset();
            }
        }
    }
    </script>
