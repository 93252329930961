<template>
    <div class="card-maintenance cr-pointer border br-10 d-flex">
        <div class="flex-fill pt-2 w-100">
            <p class="px-3 tres-puntos f-14 border-bottom pb-1 f-500"> {{ name }} </p>
            <div class="row mx-0 py-2">
                <div class="col-12">
                    <p class="f-12 f-500">Descripción:</p>
                </div>
                <div class="col-12">
                    <p class="f-12 lh-15 description text-justify overflow-hidden rompe-palabras"> {{ description }} </p>
                </div>
            </div>
            <div class="row mx-0 br-bl-5 py-1 bg-e6 justify-content-between">
                <div class="d-middle f-12 pl-3">
                    <p>Actividades</p>
                    <div class="cant-activities br-4 bg-general text-white d-middle-center ml-2">
                        {{ cant }}
                    </div>
                </div>
                <div class="d-middle icon-option pr-3">
					<el-tooltip content="Editar" effect="light" visible-arrow>
						<i class="icon-pencil text-5d f-20"
                            :class="permitAction('almacen.mantenimiento.editar') ? 'cr-pointer' : 'cr-not-allowed'" 
                            @click.stop="functionPermitAction('almacen.mantenimiento.editar', editMaintenance)"
                        />
					</el-tooltip>
					<el-tooltip content="Eliminar" effect="light" visible-arrow>
						<i class="icon-trash-can-outline text-5d f-20"
                            :class="permitAction('almacen.mantenimiento.eliminar') ? 'cr-pointer' : 'cr-not-allowed'" 
                            @click.stop="functionPermitAction('almacen.mantenimiento.eliminar', deleteMaintenance)"
                        />
					</el-tooltip>
                </div>

            </div>
        </div>
        <div class="color br-r-10 bg-general"> </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    props:{
        name:{
            type: String,
            required: true
        },
        description:{
            type: String,
            required: false
        },
        cant:{
            type: Number
        }
    },
	computed: {
		...mapGetters({
			user: 'auth/user',
		})
	},
    methods:{
        editMaintenance(){
            this.$emit('edit')
        },
        deleteMaintenance(){
            this.$emit('deleteItem')
        }
    }
}
</script>

<style lang="scss" scoped>
.card-maintenance{
    box-shadow: 0px 0px 8px #00000021;
    .color{
        min-width: 9px;
    }
    .bg-e6{
        background-color: #E6E6E6;
    }
    .description{
        min-height: 60px;
        max-height: 60px;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
    }
    .cant-activities{
        width: 37px;
            height: 23px;
    }
}
</style>